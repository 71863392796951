@import '/src/components/shared/shared.scss';



 .add-new-card {
    border:          2px dashed #4E01B0;
    display:         flex;
    align-items:     center;
    justify-content: center;

    &:hover {
        border-color: $regular-color;
    }

    & .add-new-card-button {
        display:          flex;
        width:            54px;
        height:           54px;
        border-radius:    50%;
        border:           none;
        background-color: $brand-color-shaded;
        background-image: url("/assets/vectors/add.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}