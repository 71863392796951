@import '/src/components/shared/shared.scss';

.notification-container {
    position:        absolute;
    left:            0;
    top:             0;
    display:         flex;
    flex-direction:  row;
    align-items:     center;
    justify-content: center;
    width:           100%;
    height:          25%;

    & .notification {
        display:       block;
        background:    $brand-color-shaded;
        padding:       12px;
        color:         $basic-white;
        border-radius: 15px;
        border:        2px solid $brand-color-shaded-dark;
    }

}