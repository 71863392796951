@import '/src/components/shared/shared.scss';

.custom-form-outlet {
    position:        absolute;
    height:          100%;
    width:           100%;
    left:            0;
    top:             0;
    display:         flex;
    justify-content: center;
    align-items:     center;
    padding:         0;
    position:        fixed;
}

.my-form {
    display:          flex;
    justify-content:  center;
    align-items:      flex-start;
    flex-direction:   column;
    width:            400px;
    padding:          0px;
    background-color: $basic-white;
    box-shadow:       20px 4px 80px rgba(45, 0, 102, 0.8);
    border-radius:    22px;
    opacity:          1;
    z-index:          500;
    padding:          15px;

    & div,
    form {
        width: 100%;
    }

    & .form-heading {
        display:         flex;
        justify-content: space-between;
        align-items:     center;
        margin:          0;

        & .form-title {
            font-weight: 600;
            font-size:   20px;
            line-height: 24px;
            margin:      14px 0;
            color:       $brand-color-shaded;
        }
    }

    & .form-content {
        margin:        0;
        font-weight:   600;
        font-size:     14px;
        line-height:   18px;
        margin-bottom: 20px;
    }

    & form {
        display:        flex;
        flex-direction: column;
        align-items:    flex-start;
        padding:        0px;

        & label {
            font-weight: 600;
            font-size:   14px;
            line-height: 18px;
        }
    }

    .close-button {
        background:          0, 0, 0, 0;
        border:              0;
        background-image:    url('/assets/vectors/close.svg');
        border-radius:       20%;
        background-position: center;
        background-repeat:   no-repeat;
        background-size:     cover;

        height: 32px;
        width:  32px;

        &:hover {
            background-color: rgba(150, 0, 0, 0.2);
        }
    }
}

.controls-container {
    box-sizing:    border-box;
    margin-bottom: 20px;
    max-height:    75px;

    &.checkbox-container {
        height: 18px;
    }

    & label {
        display:       inline-block;
        font-family:   'Montserrat';
        font-style:    normal;
        font-weight:   600;
        font-size:     14px;
        line-height:   18px;
        margin-bottom: 10px;
    }

    & input[type='checkbox']+label {
        margin: 0;
    }

    & span.form-error-msg {
        color:     red;
        font-size: 12px;
    }

    & .controls-input {
        box-sizing:    border-box;
        margin-bottom: 0;

        /* Auto layout */
        display:        flex;
        flex-direction: row;
        align-items:    flex-start;
        padding:        15px 0px 15px 10px;

        width:  100%;
        height: 47px;

        /* Basic/White */
        background: $basic-white;

        /* Basic/Grey border */
        border:        1px solid;
        border-color:  $basic-grey;
        border-radius: 12px;

        &:focus {
            border-color: $focus-color;
        }

        &:focus-visible {
            outline-width: 0;
        }

        &:invalid {
            border-color: $invalid-color;
        }

        &:disabled {
            background-color: $disabled-color;
        }
    }
}

.controls-button {
    display:         flex;
    flex-direction:  row;
    align-items:     center;
    justify-content: center;
    padding:         15px 20px;
    gap:             10px;
    width:           100%;

    font-family: 'Montserrat';
    font-style:  normal;
    font-weight: 600;
    font-size:   14px;
    line-height: 17px;
    /* Highlight/Bg */

    background:    $regular-color;
    border-radius: 12px;
    border:        0;

    &:hover {
        background-color: $hover-color;
    }

    &:active {
        background-color: $active-color;
    }

    &:disabled {
        background-color: $basic-grey;
    }
}