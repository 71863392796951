@import '/src/components/shared/shared.scss';

.account-tab {
    & .cards {
        display:        flex;
        flex-wrap:      wrap;
        flex-direction: row;
        // justify-content: center;

        & .card-item {
            display:       flex;
            width:         285px;
            height:        180px;
            margin: 10px;
            border-radius: 12px;
        }
    }
}

