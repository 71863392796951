@import '../forms.scss';

.operation-history {
    display:         flex;
    flex-direction:  column;
    list-style-type: none;
    padding:         0 5px;
    width:           100%;
    margin:          0;

    & .history-element {
        box-sizing:      border-box;
        display:         flex;
        flex-direction:  row;
        align-items:     center;
        justify-content: space-between;
        padding:         10px 0px;
        gap:             10px;
        height:          38px;
        width:           100%;

        font-family: 'Montserrat';
        font-style:  normal;
        font-size:   14px;
        line-height: 18px;

        & .date {
            font-weight: 400;
        }

        & .amount {
            font-weight: 600;

            &.add {
                color: $status-positive;

                &::before {
                    content: '+';
                }
            }

            &.donate {
                color: $status-negative;

                &::before {
                    content: '-';
                }
            }
        }

        & .balance {
            font-weight: 600;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $basic-grey;
        }
    }
}