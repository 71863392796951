@import '../styles.scss';

.card-item.bank-card {
    box-shadow:          0px 15px 30px #4800A4;
    display:             flex;
    flex-direction:      column;
    flex-wrap:           nowrap;
    justify-content:     space-between;
    background-repeat:   no-repeat;
    background-position: center;
    background-size:     cover;
    padding:             5px;
    color:               $basic-white;

    &.background-cat1 {
        background-image: url('/assets/img/Cat1.jpg');
    }

    &.background-cat2 {
        background-image: url('/assets/img/Cat2.jpg');
    }

    &.background-cat3 {
        background-image: url('/assets/img/Cat3.jpg');
    }

    &.background-cat4 {
        background-image: url('/assets/img/Cat4.jpg');
    }

    & * {
        border: none;
    }

    & .info-top {
        display:         flex;
        justify-content: space-between;
        flex-direction:  row;
        color:           $regular-color;
        font-style:      normal;
        font-weight:     600;
        font-size:       20px;
        line-height:     24px;

        & * {
            border-radius: 8px;
            height:        32px;
            margin-right:  5px;
        }

        & .card-balance {
            background:      rgba(0, 0, 0, 0.5);
            // min-width:       55px; //uncomment this line to fix bug
            width:       55px; // comment this line to fix bug
            display:         flex;
            align-items:     center;
            justify-content: center;
            cursor:          pointer;
            padding-left:    5px;
            padding-right:   5px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }

        & .card-button {
            background-repeat:   no-repeat;
            background-position: center;
            width:               32px;
            cursor:              pointer;

            &:hover {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }

        & .add-money {
            background-color: rgba(0, 0, 0, 0.5);
            background-image: url('/assets/vectors/add.svg');
        }

        & .delete-card {
            background-color: rgba(0, 0, 0, 0, );
            background-image: url('/assets/vectors/delete.svg');
            align-self:       flex-end;
        }
    }

    & .info-bottom {
        display:        flex;
        flex-direction: column;
        margin:         20px;

        & .cvv-container {
            display:         flex;
            flex-direction:  row;
            flex-wrap:       nowrap;
            justify-content: flex-end;
            align-items:     center;
        }

        & .exp-date-container {
            display:         flex;
            flex-direction:  row;
            flex-wrap:       nowrap;
            justify-content: flex-start;
            align-items:     center;
        }

        & .card-number {
            display:         flex;
            justify-content: center;
            white-space:     nowrap;
            text-align:      center;
            cursor:          pointer;
        }

        & .name {
            text-transform: uppercase;
        }
    }

    & .text-pre {
        display:        flex;
        flex-direction: column;
        margin-right:   5px;
    }
}

.small-text {
    font-family: 'Montserrat';
    font-style:  normal;
    font-weight: 600;
    font-size:   7px;
    line-height: 9px;
}

.medium-text {
    font-family: 'Montserrat';
    font-style:  normal;
    font-weight: 600;
    font-size:   15px;
    line-height: 18px;
}

.big-text {
    font-family: 'Montserrat';
    font-style:  normal;
    font-weight: 600;
    font-size:   23px;
    line-height: 28px;
}