@import '/src/components/Kitties/Forms/forms.scss';

.donate-form-outlet {
    max-width:            400px;
    display:          flex;
    justify-content:  center;
    align-items:      center;
    padding:          0;
    height:           600px;
    background-color: $basic-white;
    box-shadow:       20px 4px 80px rgba(45, 0, 102, 0.8);
    border-radius:    22px;
}

.donate-status-message {
    font-weight: 600;
    font-size:   32px;
    line-height: 39px;
    text-align:  center;
}

.donate-succes {
    color: $status-positive;
}

.donate-error {
    color: $status-negative;
}

.donate-form {
    display:         flex;
    justify-content: center;
    flex-direction:  column;
    width:           400px;
    padding:         20px;
}