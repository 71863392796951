@import "../colors.scss";
$my-font: 'Montserrat';

@font-face {
    font-family: 'Montserrat';
    src:         url(../../assets/fonts/Montserrat-VariableFont_wght.ttf);
}

* {
    font-family: $my-font;
    box-sizing:  border-box;

    & :not(input) {
        -moz-user-select:    none;
        -ms-user-select:     none;
        -o-user-select:      none;
        -webkit-user-select: none;
        user-select:         none;
    }
}

html,
body,
#root {
    height: 100%;
}

.shade-background {
    background-color: $brand-color-shade;
    opacity:          0.8;
    position:         absolute;
    height:           100%;
    width:            100%;
    left:             0;
    top:              0;
}

.flex-container {
    display: flex;
}

.flex-element {
    flex:   50%;
    margin: 0 20px;
}

@media (max-width: 800px) {
    #root {

        .flex-container {
            flex-direction: column;
            align-items:    center;

        }

        .flex-element {
            flex:   100%;
            margin: 0;
            width:  100%;
        }

        .app-main-frame {
            padding: 20px 0;
        }

        .app-instruction {
            padding: 20px;
        }

        .my-form {
            width: 90%;
        }

        .donate-form-outlet {
            width: 90%;
        }

        .donate-form {
            width: 100%;
        }

        .account-tab{
            max-height: 700px;  // comment this line to fix
        }
    }
}