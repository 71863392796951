@import '/src/components/shared/shared.scss';

.app-main-frame {
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
    padding:        40px;

    // max-width:  1140px;
    // min-height: 100%;

    /* Brand/1 */
    background: $brand-color;

    /* Inside auto layout */
    order:      0;
    align-self: stretch;
    flex:       80%;
}

.app-head {
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    flex-direction:  row;
    width:           100%;
    margin-bottom:   20px;
}

.active-tab {
    width: 100%
}

.tabs-switcher {
    /* Auto layout */
    display:        flex;
    flex-direction: row;
    align-items:    flex-start;
    padding:        4px;

    width:  248px;
    height: 37px;

    /* Brand/2 */
    background:    $brand-color-shaded;
    border-radius: 12px;

    /* Inside auto layout */
    flex:      none;
    order:     1;
    flex-grow: 0;

    & .tab-button {
        display:         flex;
        flex-direction:  row;
        justify-content: center;
        align-items:     flex-start;
        padding:         6px 12px;
        gap:             10px;
        color:           $basic-white;

        width:  120px;
        height: 29px;

        border-radius:    9px;
        border:           none;
        background-color: inherit;

        /* Inside auto layout */
        flex:      none;
        order:     0;
        flex-grow: 0;

        &:hover {
            background-color: $brand-color;
        }

        &.active {
            background: $brand-color-shaded-dark;
        }

    }
}